/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import Image from "../components/Image";
import {graphql} from 'gatsby'
import Layout from "../components/layout"
import "./training.css";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import MultiImageSlider from "../components/MultiImageSlider";
import {Grid, Hidden} from "@material-ui/core";
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import SEO from "../components/SEO";
import Map from '../components/MyMapComponent'
import Contact from "../components/Contact";
import Banner from "../components/Banner";
import Empfehlungen from "../components/empfehlungen";

export default class Training extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            markers: [{
                title: this.props.data.wordpressPage.acf.maps_marker_title,
                key: this.props.data.wordpressPage.acf.maps_marker_title,
                description: this.props.data.wordpressPage.acf.maps_marker_description,
                markerLink: this.props.data.wordpressPage.acf.maps_marker_link,
                lat: parseFloat(this.props.data.wordpressPage.acf.maps && this.props.data.wordpressPage.acf.maps.lat || 0),
                lng: parseFloat(this.props.data.wordpressPage.acf.maps && this.props.data.wordpressPage.acf.maps.lng || 0)
            }]
        };
    }

    render() {
        return <Layout id="training">
            <SEO title={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_title}
                 description={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
            />
            {
                this.props.data.wordpressPage.acf.banner &&
                <Banner
                    title={this.props.data.wordpressPage.acf.banner.title}
                    description={this.props.data.wordpressPage.acf.banner.description}
                    image={this.props.data.wordpressPage.acf.banner.image}
                />
            }

            <Grid container type="flex" justify="center" className='content-wrapper'>
                <Grid item xl={6} lg={8} md={12} xs={12} className='content-centered'>
                    <h2 className='title-big'>{this.props.data.wordpressPage.acf.freediving_title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: this.props.data.wordpressPage.acf.freediving_content }}></div>
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12}>
                    <div className='apnoe-intro-image'>
                        {this.props.data.wordpressPage.acf.freediving_img_1 && <Image image={this.props.data.wordpressPage.acf.freediving_img_1}/>}
                    </div>
                </Grid>
                <Grid item xl={12} lg={8} md={12} xs={12} className='content-centered'>
                    <div dangerouslySetInnerHTML={{ __html: this.props.data.wordpressPage.acf.freediving_content_2}}></div>
                </Grid>
            </Grid>

            {/*<Grid container className="trainer-content-row"  justify="center">
                <Grid item lg={6} md={12} xs={12} className="training-card">
                    <Card className="card">
                        <CardContent style={{padding: '2em'}}>
                            <h2>
                                {this.props.data.wordpressPage.acf.price.title}
                            </h2>
                            <div>
                                {this.props.data.wordpressPage.acf.price.description}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>*/}
            <Grid container className="trainer-content-row"  justify="center">
                {/*<Grid item lg={6} md={12} xs={12} className="training-card">
                    <Card className="card">
                        <CardContent style={{padding: '2em'}}>
                            <h2>
                                {this.props.data.wordpressPage.acf.training_hours.title}
                            </h2>
                            <div>
                                {this.props.data.wordpressPage.acf.training_hours.description}
                            </div>

                        </CardContent>
                    </Card>
                    <br />
                </Grid>*/}

                <Contact title={this.props.data.wordpressPage.acf.contact.title}
                         image={this.props.data.wordpressPage.acf.contact.image}
                         description={this.props.data.wordpressPage.acf.contact.description} />
            </Grid>

            <div className="video-wrapper" style={{margin: '0 auto'}}>
                <video controls className="video" poster={this.props.data.wordpressPage.acf.video.thumbnail && this.props.data.wordpressPage.acf.video.thumbnail.src}>
                    <source src={this.props.data.wordpressPage.acf.video.file.source_url} type="video/mp4" />
                </video>
            </div>

            <MultiImageSlider nodes={this.props.data.wordpressPage.acf.gallery}/>

            <Map markers={this.state.markers} />

            <div className='dark-hero-content'>
                <h2 className='title-big'>Unsere Trainer</h2>
            </div>

            <Grid container className="becometrainer-content-row"  justify="start">
                {this.props.data.wordpressPage.acf.trainers.cards.map((card, i) => {
                    return <Grid item lg={4} xs={12} style={{marginBottom: '2em'}} key={`card_${i}`}>
                        <Card className="card">
                            {
											card.image && <Image image={card.image} />
										}
                            <CardContent style={{padding: '2em'}}>
                                <h3 className='title-big'>
                                    {card.title}
                                </h3>
                                {card.description}
                            </CardContent>
                        </Card>
                    </Grid>
                })}
            </Grid>

            <Empfehlungen empfehlungen={this.props.data.wordpressPage.acf.cards_page} />
        </Layout>
    }
}

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
            acf {
                contact {
                    title
                    description
                    image {
                       alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                maps_marker_title
                maps_marker_description
                video {
                    file {
                        source_url
                    }
                    thumbnail {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                banner {
                    title
                    description
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                gallery {
                   alt_text
title
description
localFile {
                        childImageSharp {
                            id
                            fluid {
                                base64
                                tracedSVG
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                originalName
                            }
                        }
                    }
                }
                cards_page {
                    title
                    description
                    link
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                trainers {
                    cards {
                        title
                        description
                        image {
                           alt_text
title
description
localFile {
                                childImageSharp {
                                    id
                                    fluid {
                                        base64
                                        tracedSVG
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        originalName
                                    }
                                }
                            }
                        }
                    }
                }
                freediving_title
                freediving_content
                freediving_content_2
                freediving_img_1 {
                   alt_text
title
description
localFile {
                        childImageSharp {
                            id
                            fluid {
                                base64
                                tracedSVG
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                originalName
                            }
                        }
                    }
                }
            }
        }
    }
`;


